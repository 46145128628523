import React from "react";
// import ReactDOM from "react-dom";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import Navbar from "/src/containers/navbar";

import AssetAd from "/src/containers/asset-advertising";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";

import config from "/src/config.js";
import SEO from "/src/components/seo";

const AssetsPage = () => {
  return (
    <>
      <SEO
        title={`${config.CLIENT_NAME} - Advertising`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <Router basePath={"/"}>
          <AssetAd path={"active-adspaces/:assetId"} />
        </Router>
      </BacThemeProvider>
    </>
  );
};

export default AssetsPage;
